@import 'mixin/media';
@import 'buttons';

img,
svg {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  color: #ffffff;
  text-align: center;
}
html,
body {
  font-family: Poppins !important;
  // background: linear-gradient(to left, #081f6b 0%, #030c2c 100%);
  background: linear-gradient(90deg, #030D2F 0%, #051855 53.5%, #061D64 101.65%);
}

html:focus-within {
  scroll-behavior: smooth;
}

#root {
  width: 100%;
}

main.wrapper {
  position: relative;
  overflow: hidden;
}

.container {
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1280px;
  width: 100%;
  box-sizing: border-box;

  margin-left: auto;
  margin-right: auto;

  @include media-md {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.web3modal-modal-lightbox {
  z-index: 6 !important;
}

.badge {
  position: relative;
  .badge-main {
    border-radius: 50%;

    width: 31px;
    height: 31px;

    background: #030e31;
    border: 1px solid #010923;
    box-sizing: border-box;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .badge-info {
    background: #030e31;
    border: 0.717195px solid #26cb5c;
    box-sizing: border-box;

    color: #26cb5c;
    font-weight: 600;
    font-size: 6.68257px;

    width: 12px;
    height: 12px;
    border-radius: 50%;

    position: absolute;
    right: -6px;
    top: 0px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.showed {
  opacity: 1 !important;
}
