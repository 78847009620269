@import 'src/scss/mixin/media';

.header :global {
  position: relative;
  z-index: 5;

  height: 108px;
  width: 100%;
  z-index: 5;

  margin-left: auto;
  margin-right: auto;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .logo {
    display: flex;
    align-items: center;

    svg {
      max-width: unset;
      width: 200px;
      height: 36px;
    }
  }

  .main-menu {
    ul li {
      display: inline;
      padding: 8px;
      button.btn-link,
      a {
        cursor: pointer;
        color: white;
        font-weight: normal;
        padding: unset;
        background: none;
        border: none;
        text-decoration: none;
      }
    }
  }

  .mobile-links {
    display: flex;
    justify-content: center;
    align-items: center;
    .img-wallet {
      display: none;
      width: 19px;
      margin-right: 30px;
    }
    display: none;
  }

  .mobile-menu-wrap {
    display: none;
    z-index: 9999;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;

    span:nth-child(1) {
      margin-top: 7px;
      z-index: 9;
    }
    span:nth-child(2) {
      margin-top: 12px;
    }
    span:nth-child(3) {
      margin-top: 17px;
    }
    span {
      display: block;
      height: 3px;
      width: 24px;

      position: absolute;
      right: 5px;
      top: 0;

      background-color: #c4c4c4;
      box-sizing: content-box;
      overflow: hidden;
      transition: all 0.4s ease;
    }
    .mobile-menu {
      position: absolute;
      border-radius: 6px;

      height: 0;
      width: 310px;
      max-width: calc(100vw - 80px);
      right: 0;
      top: 40px;
      z-index: 18;
      overflow: hidden;

      padding: 0px;

      background: white;
      text-align: center;
      transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      li {
        display: block;
        clear: both;
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        button.btn-link, a {
          background: none;
          text-decoration: none;
          color: #081f6a;
          border: none;
          padding: unset;
          font-size: 13px;
          font-weight: 600;
        }
        button {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
  }

  .mobile-menu-wrap.opened {
    .mobile-menu {
      opacity: 1;
      height: 230px;
      margin: 0;
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);

      padding: 27px 10px 15px 10px;
      li {
        margin-bottom: 20px;
      }
    }
    span:nth-child(1) {
      z-index: 100;
      transform-origin: 5px 1.5px;
      transform: rotate(45deg);
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      z-index: 100;
      transform-origin: 5px 1.5px;
      transform: rotate(-45deg);
    }
    span {
      background-color: white;
      border: none;
    }
  }

  @include media-md {
    height: 50px;

    .logo svg {
      margin-top: 10px;
      width: 163px !important;
      height: 29px !important;
    }

    .main-menu {
      display: none;
    }

    .mobile-links {
      display: flex;
    }
    .mobile-links .img-wallet,
    .mobile-menu-wrap {
      display: block;
    }

    .btn-container {
      display: none;
    }
  }
}
