@import 'src/scss/mixin/media';

.footer :global {
  background: #04113b;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 55px;
    padding-bottom: 65px;

    .left-side {
      .cert-icon {
        opacity: 0;
        margin-top: 10px;
        img {
          transform: rotate(0.0000000001deg);
          width: 106px;
        }
      }
      .icons {
        margin-top: 30px;

        display: flex;
        align-items: center;
        gap: 15px;

        img {
          width: 32px;
        }

        #hover_tooltip {
          border-radius: 8px;

          padding: 17px 16px 15px 16px;

          a {
            font-weight: bold;
            font-size: 13px;
            line-height: 16px;
            text-transform: uppercase;
            text-decoration: none;
            color: #000000;

            transition: all 0.2s ease-in-out;
            &:hover {
              color: #27cc5d;
            }
          }
        }
      }
    }

    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .button-cta h4 {
        display: none;
      }

      .links {
        margin-top: 30px;

        display: flex;
        gap: 30px;

        a {
          text-decoration: none;
          color: #828799;
        }
      }
    }
  }

  @include media-md {
    .container {
      flex-direction: column;

      gap: 40px;
      .left-side {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          max-width: unset;
          width: 200px;
          height: 36px;
        }

        .icons {
          margin-top: 10px;
          img {
            width: 32px;
          }

          #hover_tooltip {
            padding: 4px 10px 5px 10px;

            a {
              font-size: 10px;
            }
          }
        }
      }

      .right-side {
        align-items: center;

        a {
          font-size: 11px;
        }
      }
    }
  }
}
