@import 'src/scss/mixin/media';

.contract-address {
  margin-bottom: 130px;

  text-align: center;

  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: white;
  a {
    color: #27cc5d;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
  @include media-md {
    font-size: 14px;
    margin-bottom: 85px;

    a {
      display: block;
      word-break: break-all;
    }
  }
}
