.goat-wrapper {
  main {
    min-height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .load-section {
    padding-top: 150px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      max-width: 781px;
      font-size: 64px;
      line-height: 120%;
      color: black;
    }

    img {
      margin-top: 50px;
      width: 500px;
      transform: rotate(45deg);
    }
  }
  .goat-section {
    padding: 50px 0 50px 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .goat-dialog {
      display: flex;
      justify-content: space-around;
      align-items: center;

      max-width: 1194px;
      background: #ffffff;
      box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.13),
        16px 24px 54px rgba(224, 224, 224, 0.83);

      padding: 13px;
      div {
        &:first-of-type {
          width: 55%;
          img {
            display: block;
          }
        }
        &:last-of-type {
          width: 45%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h2 {
            max-width: 488px;
            font-size: 36px;
            line-height: 120%;
            color: black;
          }
          h4 {
            margin-top: 15px;
            font-weight: bold;
            font-size: 24px;
            line-height: 36px;
            color: #10bb54;
          }
          span {
            margin-top: 19px;
            font-size: 14px;
            line-height: 21px;
            color: #565656;
          }
          .btn {
            max-width: 413px;
            border-radius: 4px;
            border: none;

            font-size: 18px;
            line-height: 18px;
            color: white;
            height: 60px;

            display: flex;
            justify-content: center;
            align-items: center;

            gap: 10px;
          }
          .btn-opensea {
            margin-top: 28px;
            background: #2081e2;
          }
          .btn-etherscan {
            margin-top: 15px;
            background: #21325b;
          }
        }
      }
    }
    .btn-lightgreen {
      margin-top: 98px;
      width: 531px;
      height: 95px;
      background: #10bb54;
      box-shadow: 16px 17px 17px rgba(0, 0, 0, 0.22);
      border-radius: 52px;
      color: #ffffff;
      font-size: 24px;
      font-weight: normal;
      line-height: 120%;
      letter-spacing: -0.02em;
      text-transform: none;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
