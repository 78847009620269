@import 'src/scss/mixin/media';

.section-goats {
  width: 100%;
  padding-top: 200px;
  .goat-list {
    display: flex;
    justify-content: center;
    align-items: center;

    .goat-item {
      margin: 5px;
      img {
        border-radius: 3.14667px;
        width: 180px;
      }
      .goat-type {
        border: 1.04889px solid rgba(255, 255, 255, 0.15);
        box-sizing: border-box;
        border-radius: 3.14667px;
        padding: 10px;
        text-align: center;

        font-size: 14.6844px;
        line-height: 18px;
        color: #ffffff;
      }
    }
  }

  .left-info {
    margin-top: 110px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      text-align: center;
    }
  }

  @include media-md {
    padding-top: 50px;
    .goat-list {
      flex-direction: column;
      flex-wrap: wrap;
      .goat-item {
        width: 80%;
        img {
          width: 100%;
        }
        .goat-type {
          font-size: 8.09702px;
          line-height: 10px;
        }
      }
    }
    .left-info {
      margin-top: 50px;
    }
  }
}
