@import 'src/scss/mixin/media';

@keyframes floating {
  0% {
    margin-top: 200px;
  }
  50% {
    margin-top: 230px;
  }
  100% {
    margin-top: 200px;
  }
}

@keyframes floating-mobile {
  0% {
    margin-top: -10%;
  }
  50% {
    margin-top: -8%;
  }
  100% {
    margin-top: -10%;
  }
}

.section-how-it-works {
  width: 100%;

  padding: 185px 0 0 0;

  h2 {
    font-weight: 800;
    font-size: 64px;
    line-height: 77px;
    text-align: center;
    letter-spacing: -0.015em;

    color: #ffffff;

    text-align: center;

    margin-bottom: 100px;
  }

  .collection-mobile {
    display: none;
  }

  .img-goat {
    position: absolute;
    left: calc(50vw - 368px);
    margin-top: 210px;
    animation: floating 3s infinite ease-out;
  }

  @include media-md {
    padding-top: 0;
    h2 {
      font-weight: 800;
      font-size: 32px;
      line-height: 39px;

      margin-bottom: 50px;
    }

    .img-goat {
      width: 50%;
      left: calc(50vw - 33%);
      animation: floating-mobile 2s infinite ease-out;
    }

    .collection-mobile {
      display: flex;
      flex-direction: column;

      gap: 30px;
      .work-screen-container {
        height: 55vw;

        display: flex;
        align-items: center;
      }
      .collection-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        gap: 20px;
      }
      .collection-item {
        position: relative;

        width: 140px;
        height: 50px;

        display: flex;
        align-items: center;
        justify-content: center;

        .background {
          position: absolute;
        }
        .collection-item-content {
          position: relative;
          z-index: 5;

          display: flex;
          justify-content: center;
          align-items: center;

          font-weight: bold;
          font-size: 14px;
          line-height: 120%;
          color: #f4faf8;

          gap: 5px;
        }
      }
    }

    .img-background {
      display: none;
    }
  }
}
