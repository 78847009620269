@import 'src/scss/mixin/media';
.mygoats header {
  background: #041444;
}
.mygoats main {
  background: linear-gradient(173.65deg, #020b29 -9.4%, #041549 65.95%);
  min-height: calc(100vh - 361px);
  .goat-wrapper {
    padding: 25px 0 35px 0;
    .goat-list {
      display: grid;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      row-gap: 13px;
      column-gap: 13px;
    }
  }

  .nav-header {
    width: 100%;
    height: 140px;

    background: #041444;
    box-shadow: 0px 12px 22px -6px rgba(2, 10, 34, 0.57);

    .container {
      padding-top: 50px;

      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 48px;
      }
    }
  }

  @include media-md {
    .goat-wrapper {
      padding-top: 22px;
      padding-bottom: 22px;
      .goat-list {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }
    .nav-header {
      height: 170px;
      .container {
        padding-top: 30px;
        flex-direction: column;
        gap: 20px;
        h2 {
          text-align: left;
          font-size: 40px;
        }
        .button-cta {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }

  @include media-sm {
    .goat-wrapper {
      .goat-list {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
    }
  }

  @include media-tn {
    .goat-wrapper {
      .goat-list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}
