@import 'src/scss/mixin/media';

.section-faq {
  padding: 165px 0 117px 0;

  position: relative;
  h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.015em;
    color: #ffffff;
  }

  .img-stars {
    position: absolute;
  }

  .icons {
    position: relative;
    z-index: 2;

    img {
      width: 32px;
    }

    margin-top: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    gap: 10px;
  }

  .faq-list {
    margin-top: 48px;

    position: relative;
    z-index: 5;
  }

  @include media-md {
    padding: 100px 0 70px 0;
    h2 {
      font-size: 32px;
      line-height: 39px;
    }
  }
}
