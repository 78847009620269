@import 'src/scss/mixin/media';

.section-welcome {
  padding: 80px 0 220px 0;
  width: 100%;
  position: relative;

  .welcome-title {
    max-width: 50%;
    position: relative;
    z-index: 4;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    h3 {
      margin-top: 20px;

      font-family: Bungee;
      font-size: 25.6777px;
      line-height: 31px;
      text-transform: uppercase;

      color: #ffffff;
    }

    p {
      max-width: 565px;
      text-align: center;

      font-size: 18px;
      font-weight: 500;
      line-height: 150%;

      color: #8b95b4;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .left-info {
      margin-top: 40px;

      max-width: 480px;
      margin-left: auto;
      margin-right: auto;
      span {
        display: block;
        font-size: 18px;

        text-align: center;
        margin-top: 15px;
      }
      .button-cta {
        z-index: 100;
      }
    }
  }

  .img-welcome {
    position: absolute;
    z-index: 1;
    right: 0px;
    bottom: 0px;
    height: 100%;
  }

  @include media-md {
    padding: 40px 0 0 0;
    .welcome-title {
      max-width: unset;

      img {
        max-width: 100%;
      }

      h3 {
        max-width: unset;
      }
      p {
        max-width: unset;
        font-size: 14px;
      }
    }

    .img-welcome {
      position: relative;
      height: unset;
      max-width: unset;
      width: 100vw;
    }
  }
}
