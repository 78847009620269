@import 'src/scss/mixin/media';

.goat-wrapper {
  main {
    min-height: calc(100vh - 475px);
    padding: 50px 0 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
      font-weight: bold;
      font-size: 48px;
      text-align: left;
    }
    span.sub-title {
      margin-top: 10px;

      font-weight: 500;
      font-size: 18px;
    }
    .icon-container {
      margin-top: 40px;

      display: flex;
      align-items: center;
      gap: 25px;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        color: white;
        font-weight: bold;
        font-size: 14px;
        img {
          width: 32px;
        }
        a {
          color: white;
          font-weight: bold;
          font-size: 18px;
          padding-left: 5px;
          margin-bottom: 5px;

          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
    h5 {
      margin-top: 70px;
      font-weight: bold;
      font-size: 18px;
      text-align: left;
    }
    .table-container {
      margin-top: 20px;
      overflow-x: scroll;
      table {
        width: 100%;

        color: white;
        font-weight: 500;
        font-size: 12px;
        line-height: 160%;

        thead th {
          text-align: left;
          padding: 10px 20px;
        }
        tr {
          td {
            padding: 10px 20px;
            border-bottom: 2px solid rgba(255, 255, 255, 0.24);
            border-right: 2px solid rgba(255, 255, 255, 0.24);

            &:last-of-type {
              border-right: none;
            }
            a {
              color: white;
              font-size: 14px;
            }
          }
          &:first-of-type td {
            border-top: 2px solid rgba(255, 255, 255, 0.24);
          }
        }
      }
    }

    @include media-md {
      h2 {
        font-size: 31px;
      }
      span.sub-title {
        font-weight: normal;
        font-size: 14px;
      }
      h5 {
        margin-top: 20px;
      }
      .table-container {
        overflow-x: scroll;
      }
    }
  }
}
