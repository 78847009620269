@import 'src/scss/mixin/media';

.button-email {
  display: flex;
  flex-direction: column;
  justify-content: center;

  max-width: 474px;

  div.button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 0px;

    box-shadow: 5px 18px 19px -2px rgba(0, 0, 0, 0.21);

    div.email {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;

      color: #53df81;
      background: white;
      height: 66px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 3px 0 0 3px;

      width: 274px;

      input {
        height: 90%;
        padding: 0 20px;
        border: none;

        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #828799;
      }
    }

    button {
      background: linear-gradient(96.43deg, #547fef 25.97%, #162cf4 98.34%);
      box-sizing: border-box;
      border: none;
      border-radius: 0px 3px 3px 0px;
      min-height: 66px;
      width: 200px;

      color: white;
      text-transform: uppercase;
    }
  }

  @include media-md {
    max-width: 310px;

    div.button-wrapper {
      flex-direction: column;
      gap: 10px;
      div {
        font-size: 13.4458px !important;
        line-height: 16px !important;

        width: 100% !important;
        height: 50px !important;
      }

      button {
        font-size: 13.4458px !important;
        line-height: 16px !important;

        width: 100% !important;
        min-height: 50px !important;
      }
    }
  }
}
