@import 'src/scss/mixin/media';

.section-service {
  width: 100%;

  background: white;

  padding: 107px 0 156px 0;
  z-index: 2;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;

    letter-spacing: -0.015em;
    color: #000414;

    max-width: 944px;
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;

    color: #000414;

    max-width: 869px;
    margin-top: 27px;
  }

  a {
    margin-top: 25px;

    font-weight: bold;
    font-size: 13px;
    line-height: 16px;

    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 80px;
    text-decoration: none;
    text-align: center;

    background: #ececec;
    border: 1px solid #d6d7d7;
    box-sizing: border-box;
    border-radius: 5px;

    color: #2f3035;
  }

  .compute-result {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 65px;
    margin-top: 64px;

    .compute-item {
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.015em;
      color: #9b9fac;

      h4 span {
        font-weight: bold;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -0.015em;

        color: #27cc5d;
      }
    }
  }

  img {
    margin-top: 67px;
  }

  span.service-description {
    margin-top: 11px;

    font-style: italic;
    font-size: 12px;
    line-height: 18px;

    color: #000000;

    max-width: 815px;
  }
  @include media-md {
    padding: 38px 0 20px 0;
    h2 {
      font-size: 24px;
      line-height: 110%;
    }
    p {
      font-size: 14px;
    }

    a {
      width: 100%;
    }

    .compute-result {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 5px;
      .compute-item {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 17px;

        text-align: center;
      }
    }
  }
}
