@import 'src/scss/mixin/media';

.button-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  width: 100%;
  max-width: 480px;

  div.button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 10px;

    padding: 6px;

    background: #ffffff;
    box-shadow: 0px 21px 4px -14px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    div.price {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.015em;

      color: black;

      display: flex;
      align-items: center;
      justify-content: center;

      gap: 10px;

      margin-left: 15px;
      
      span {
        margin-top: 0;
        color: #030d2e;
      }
    }

    button {
      background: #2aca5b;
      border: 1px solid #20ac4b;
      border-radius: 10px;
      box-sizing: border-box;

      width: 160px;
      max-height: unset;
      height: 60px;
      color: white;
      text-transform: uppercase;
    }
  }

  div.button-wrapper2 {
    display: flex;
    justify-content: center;
    align-items: center;

    gap: 0px;

    box-shadow: 5px 18px 19px -2px rgba(0, 0, 0, 0.21);

    div.price {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.01em;

      color: #53df81;
      background: white;
      height: 66px;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 3px 0 0 3px;

      width: 274px;

      span {
        color: #030d2e;
      }
    }

    button {
      background: linear-gradient(96.43deg, #c3c4c3 25.97%, #c3c4c3 98.34%);
      border: 1px solid #8f9390;
      box-sizing: border-box;
      border-radius: 0px 3px 3px 0px;
      min-height: 66px;
      width: 200px;

      color: white;
      text-transform: uppercase;
    }
  }

  h4 {
    display: none;
    margin-top: 15px;
    text-align: left;
  }

  @include media-md {

    div.button-wrapper {
      gap: 2px;
      div.price {
        flex-direction: column;
        gap: 2px;
        margin-left: 5px;
      }
    }

    h4 {
      display: none;
    }
  }
}
