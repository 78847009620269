@import 'src/scss/mixin/media';

.transaction-error {
  min-height: calc(100vh - 375px);
  .error-container {
    padding-top: 77px;
    padding-bottom: 128px;
  }
  .error-dialog {
    background: #ffffff;
    box-shadow: 12px 21px 17px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    padding: 100px 60px 120px 60px;

    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 904px;
    margin-left: auto;
    margin-right: auto;
    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: 90%;

      color: #140000;

      text-align: center;
    }
    span {
      display: block;
      max-width: 600px;

      margin-top: 26px;

      font-weight: bold;
      font-size: 22px;
      line-height: 150%;

      color: #585a5f;

      text-align: center;
    }
    a {
      margin-top: 24px;

      font-weight: bold;
      font-size: 15px;
      line-height: 120%;

      letter-spacing: unset;
      text-transform: none;
      color: #547fef;

      text-align: center;
    }
    button {
      margin-top: 30px;

      padding: 20px 80px;

      font-weight: bold;
      font-size: 20px;
      line-height: 120%;

      text-transform: none;

      background: #21325b;
      border-radius: 4px;
      color: white;
    }
  }

  @include media-md {
    .error-container {
      padding: 10px 15px 15px 15px;
      .error-dialog {
        padding: 40px 18px 30px 18px;

        h2 {
          font-size: 20px;
          line-height: 110%;
        }
        span {
          margin-top: 20px;

          font-size: 16px;
          line-height: 150%;
        }
        a {
          margin-top: 30px;

          font-size: 14px;
          line-height: 120%;

          letter-spacing: unset;
          text-transform: none;
        }
        button {
          margin-top: 30px;

          font-size: 14px;
          line-height: 120%;
          letter-spacing: unset;

          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
