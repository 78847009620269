@import 'src/scss/mixin/media';

@keyframes plane-animation {
  from {
    top: -800px;
  }
}

@keyframes car-animation {
  from {
    left: 1000px;
  }
  to {
    left: 0px;
  }
}

@keyframes saying-animation {
  from {
    left: 1250px;
  }
}

.section-get-in-with-the-goat {
  width: 100%;
  padding: 150px 0 0 0;

  .container {
    position: relative;

  }
  .img-stars {
    position: absolute;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  .img-goat {
    position: absolute;
    left: 520px;
    bottom: -220px;

    transform: rotateY(180deg);
    width: 60%;
  }

  h2 {
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
    text-align: left;

    color: #ffffff;

    max-width: 564px;
  }

  p {
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    color: #8b95b4;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    max-width: 583px;

    strong {
      color: #c9cfe4;
    }
  }

  @include media-md {
    padding: 50px 0 0 0;

    .img-goat {
      margin-top: 30px;

      position: relative;
      bottom: unset;
      left: unset;
      max-width: unset;
      width: 150%;
    }

    h2 {

      max-width: unset;
      font-weight: 800;
      font-size: 32px;
      line-height: 110%;
    }

    p {
      max-width: unset;

      font-size: 14px;
      line-height: 150%;
    }
  }
}
