@import 'src/scss/mixin/media';

.section-teammember {
  width: 100%;

  .img-background {
    opacity: 0.61;
    mix-blend-mode: soft-light;
    position: absolute;
    margin-left: -30px;

    z-index: 0;
  }

  h2 {
    padding-top: 130px;
    font-weight: bold;
    font-size: 48px;
    line-height: 58px;
    text-align: center;

    color: #ffffff;
  }

  div.team-container-mobile {
    display: none;
    img {
      position: absolute;
    }
  }

  div.team-container-desktop {
    position: relative;

    display: flex;
    justify-content: center;
  }

  .button-cta {
    margin-left: auto;
    margin-right: auto;

    position: relative;
    z-index: 5;
    h4 {
      text-align: center;
    }
  }

  @include media-md {
    h2 {
      font-size: 32px;
      line-height: 39px;
    }

    div.team-container-mobile {
      margin-top: 40px;

      position: relative;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;

      height: unset;
      img {
        position: unset;

        width: 130px;
        height: 130px;

        top: unset;
        left: unset;
      }
    }

    div.team-container-desktop {
      display: none !important;
    }

    .button-cta {
      display: none;
    }
  }
}
