@import 'src/scss/mixin/media';

@keyframes reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.section-roadmap {
  position: relative;

  padding: 200px 0 100px 0;
  h2 {
    font-size: 64px;
    line-height: 120%;
    text-align: center;

    margin-bottom: 65px;
  }

  .roadmap-progress-item {
    opacity: 0;

    .roadmap-item {
      display: flex;
      align-items: center;
      div {
        border-radius: 50%;
        background: #10bb54;
        width: 80px;
        height: 80px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
      }
      span {
        max-width: 620px;
        text-align: left;
        margin-left: 27px;

        font-weight: bold;
        font-size: 20px;
        line-height: 120%;
      }
    }
    svg {
      width: 8px;
      height: 30px;

      padding: 5px;
      margin-left: 31px;
    }

    &:last-of-type {
      svg {
        display: none;
      }
    }
  }
  .roadmap-progress-item.animation {
    @for $i from 1 through 8 {
      &:nth-of-type(#{$i}) {
        $delay: 0.2s * $i;
        animation: reveal 0.8s forwards $delay ease-in-out;
      }
    }
  }

  .img-stars {
    position: absolute;
  }

  @include media-md {
    padding: 100px 0 80px 0;
    h2 {
      font-size: 37.2379px;
      line-height: 45px;
    }

    .roadmap-progress-item {
      .roadmap-item {
        display: flex;
        align-items: center;
        div {
          width: 45px;
          min-width: 45px;
          height: 45px;

          font-size: 10.861px;
        }
        span {
          font-size: 12px;
          line-height: 120%;
          max-width: 230px;
        }
      }
      svg {
        margin-left: 16px;
        padding: 2.5px;
      }
    }
  }
}
