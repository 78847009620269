.m-slider {
  width: 100%;
  padding: 20px 0 0 0;
  .slider-body-outline {
    position: relative;

    box-sizing: border-box;
    height: 19px;
    padding: 1px;
    border-radius: 19.5px;
    border: 1px solid #26cb5c;

    .slider-body {
      background: linear-gradient(90deg, #5580f0 0%, #4fdd7e 100%);
      height: 100%;
      border-radius: 19.5px;

      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .slider-handle {
      position: absolute;
      top: -5px;
      width: 10px;
      height: 25px;

      background: #26cb5c;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 19.5px;
    }

    .slider-body-divider {
      // margin-left: calc(100% / 19 - 1px);
      // margin-top: 2px;

      width: 1px;
      height: 11px;
      box-sizing: border-box;

      background: #ffffff;
      border-radius: 19.5px;
    }
  }

  span {
    display: block;
    margin-top: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
  }
  .txt-common {
    float: left;
    color: #5583ed;
  }

  .txt-legend {
    float: right;
    color: #50db82;
  }
}
