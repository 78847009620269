@import 'src/scss/mixin/media';

.faq-item {
  margin-bottom: 14px;
  width: 500px;

  background: #05164a;
  border: 1px solid #030f34;
  box-sizing: border-box;
  box-shadow: 7px 10px 13px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  .content {
    display: none;
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
    color: white;
  }

  .title-bar {
    text-align: left;
    cursor: pointer;
    padding: 27px 40px 27px 27px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    line-height: 27px;
    color: white;
  }
  .title-bar span::after {
    content: '+';
  }

  @include media-md {
    width: 100%;
    margin-bottom: 8px;

    .title-bar {
      font-size: 14px;
      line-height: 17px;
      padding: 15px 14px 15px 14px;
    }
    .content {
      font-size: 13px;
    }
  }
}
.faq-item.opened {
  .title-bar span::after {
    content: '-';
  }
  .content {
    display: block;
    padding: 0px 40px 20px 27px;
    text-align: left;
  }
}
