@import 'src/scss/mixin/media';

.privacy-policy {
  background: linear-gradient(173.65deg, #020b29 -9.4%, #041549 65.95%);
}

.privacy-policy main {
  img {
    position: absolute;
    filter: drop-shadow(-64px -3px 250px rgba(0, 0, 0, 0.23));
    width: 80vw;
    top: -20vh;
  }
  .container {
    padding-top: 100px;
    padding-bottom: 80px;

    position: relative;
    z-index: 2;
  }
  h2 {
    font-size: 64px;
    line-height: 90%;

    text-align: left;
  }
  .policy-box {
    margin-top: 50px;

    background: #ffffff;
    box-shadow: 12px 21px 17px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    padding: 30px;

    font-size: 18px;
    line-height: 150%;
    color: #292c30;

    margin-left: auto;
    margin-right: auto;

    span {
      display: block;
      border: 1px solid #031037;
    }
  }
  @include media-md {
    .container {
      padding-top: 20px;
      padding-bottom: 5px;
    }
    h2 {
      font-size: 40px;
    }
    .policy-box {
      margin-top: 20px;
      margin-left: -30px;
      margin-right: -30px;
      border-radius: 0px;

      font-size: 13px;
    }
    img {
      display: none;
    }
  }
}
