.btn {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.09em;
  text-transform: uppercase;
  text-align: center;

  border-radius: 100px;
  border: none;

  display: block;

  max-width: 100%;
  max-height: unset;

  padding: 10px 30px;

  font-size: 11px;
  line-height: 13px;
  p {
    margin: 0;
  }

  @include media-md {
    width: 100% !important;
  }
}

.btn-green {
  background: #2ACA5B;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  color: white;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  text-transform: none;
  // box-sizing: content-box;
  padding-left: unset;
  padding-right: unset;

  width: 230px;
  height: 50px;
  max-height: 50px;
}

.btn.wallet-connected {
  background: #081d65;
  border: 1px solid #26cb5c;
  box-sizing: border-box;
  border-radius: 5px;

  color: #22b55c;

  @include media-md {
    background: #ff4848;
    border: 1px solid #ff4848;
    color: white;
    p {
      display: none;
    }
    &::after {
      content: 'Disconnect Wallet';
    }
  }
}

.btn.wallet-connected:hover {
  background: #ff4848;
  border: 1px solid #ff4848;
  color: white;
  p {
    display: none;
  }
  &::after {
    content: 'Disconnect Wallet';
  }
}

.btn-outline-white {
  height: 46px;
  width: 220px;
  padding: initial;
  font-size: 14px;
  line-height: 21px;
  color: #d8e2ef;

  background: none;
  border: 2px solid #7a8695;
  box-sizing: border-box;
  border-radius: 34px;
}
