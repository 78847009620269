@import 'src/scss/mixin/media';
.purchase-page {
  background: linear-gradient(173.65deg, #020b29 -9.4%, #041549 65.95%);
}
.purchase-page main {
  padding: 60px 0 100px 0;
  min-height: calc(100vh - 540px);

  .container {
    min-height: calc(100vh - 540px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .purchase-dialog {
      display: flex;
      justify-content: space-around;
      align-items: center;

      width: 920px;
      max-width: 100%;

      background: #ffffff;
      box-shadow: 12px 21px 17px rgba(0, 0, 0, 0.25);
      border-radius: 3px;

      padding: 5px;
      h4 {
        display: none;
      }

      .img-section {
        padding: 25px 40px;
        width: 40%;
        img {
          width: 100%;
        }
        .m-goat-item {
          aspect-ratio: 292 / 433;
        }
      }
      .info-section {
        flex-grow: 1;

        .info-container {
          max-width: 413px;

          margin-left: auto;
          margin-right: auto;

          display: flex;
          flex-direction: column;
          align-items: center;
        }

        h3 {
          max-width: 335px;

          font-size: 32px;
          line-height: 120%;
          letter-spacing: -0.02em;

          color: #040d19;
        }

        p {
          margin-top: 20px;
          margin-bottom: 0px;

          font-weight: 600;
          font-size: 18px;
          line-height: 27px;

          color: #565656;
        }

        .btn {
          max-width: 413px;
          width: 100%;
          height: 60px;

          border-radius: 4px;
          border: none;

          font-size: 18px;
          line-height: 18px;
          color: white;
          text-decoration: none;

          display: flex;
          justify-content: center;
          align-items: center;

          gap: 10px;
          padding: 0;
        }

        .btn-opensea {
          margin-top: 8px;
          background: #2081e2;
        }
        .btn-etherscan {
          margin-top: 15px;
          background: #21325b;
        }
      }
    }

    h5 {
      margin-top: 60px;

      font-weight: 500;
      font-size: 24px;
      color: #8b95b4;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .button-email {
      margin-top: 25px;
      flex-direction: column-reverse;

      h4 {
        text-align: center;
        margin-bottom: 25px;
      }
    }
  }

  @include media-md {
    padding: 40px 0 50px 0;

    .container {
      min-height: calc(100vh - 130px);

      .purchase-dialog {
        flex-direction: column;

        h4 {
          margin-top: 20px;

          display: block;
          font-size: 26px;
          line-height: 100%;
          color: #040d19;
        }

        .img-section {
          width: 100% !important;
        }
        .info-section {
          width: 100%;
          h3 {
            display: none;
          }
          span {
            margin-top: 10px !important;
          }
          p {
            margin-top: 20px !important;
            margin-bottom: 0px !important ;
          }
          .btn-opensea,
          .btn-etherscan {
            margin-top: 5px !important;

            font-size: 12px;
            height: 42px;
          }
        }
      }

      h5 {
        margin-top: 35px;

        font-weight: 500;
        font-size: 14px;
      }
      .button-cta {
        margin-top: 10px;
      }
    }
  }
}
